import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { UnishieldFetchStore } from 'src/app/shared/data-access/unishield/unishield.store';
import { UniAuthFacade } from "unifonic-spa-common/src";


type State = {}

const initialState: State = {}

@Injectable()
export class PageIndexStore extends ComponentStore<State>{
    constructor(
        private unishieldFetchStore: UnishieldFetchStore,
        private authFacade: UniAuthFacade,
    ){
        super(initialState)
    }



    //selectors




    vm$ = this.select(
      () => {
          return {
          
          }
      }
    )

}
