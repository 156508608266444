import {v1 as uuid} from 'uuid';

import {AdditionalGrants, FeatureFlagKeys, Permission} from '../../uni-auth/shared';
import {NavMenu, Products} from './uni-nav.model';

// TODO: Consider creating a single source of truth for permissions.
// Now permissions list related to route access is copy pasted from related permission services
// used, for example, in route guards.

// TODO: Some of the navigation items are commented out at the moment, because we're adding navigation items gradually.
// I kept those items so the information about icon name and label key is not lost and can be used later.
export const navMenuSettings: NavMenu = {
    groups: [
        {
            items: [
              {
                    id: uuid(),
                    iconName: 'uni-flow-studio',
                    labelKey: 'flowStudio.flowStudio',
                    link: {
                      product: Products.flow,
                      route: '/'
                    },
                    items: [
                      {
                        id: uuid(),
                        labelKey: 'flowStudio.dashboard',
                        link: {
                          product: Products.flow,
                          route: '/dashboard',
                        }
                      },
                      {
                        id: uuid(),
                        labelKey: 'flowStudio.myFlows',
                        link: {
                          product: Products.flow,
                          route: '/flows',
                        }
                      },
                      {
                        id: uuid(),
                        labelKey: 'flowStudio.templates',
                        link: {
                          product: Products.flow,
                          route: '/v2/templates/template-list',
                        }
                      },
                      {
                        id: uuid(),
                        labelKey: 'flowStudio.executions',
                        link: {
                          product: Products.flow,
                          route: '/reports',
                        }
                      },
                      {
                        id: uuid(),
                        labelKey: 'flowStudio.channel-executions',
                        link: {
                          product: Products.flow,
                          route: '/channel-reports',
                        }
                      },
                    ].map(item => ({
                      ...item,
                      accessData: {
                        permissions: {
                          permissionPath: 'FS',
                        },
                      },
                    })),
              },
              {
                id: uuid(),
                iconName: 'uni-multichannel',
                labelKey: 'campaigns.campaigns',
                link: {
                    product: Products.campaign,
                    route: '/',
                },
                accessData: {
                  permissions: {
                    permissionPath: 'CC.CAMPAIGN',
                  },
                  featureFlag: FeatureFlagKeys.sms_pro_2022_q_3
                },
                // items: [
                //     {
                //         id: uuid(),
                //         labelKey: 'campaigns.overview',
                //     },
                //     {
                //         id: uuid(),
                //         labelKey: 'campaigns.allCampaigns',
                //     },
                //     {
                //         id: uuid(),
                //         labelKey: 'campaigns.createCampaign',
                //     },
                // ],
              },
              {
                id: uuid(),
                iconName: 'uni-multichannel',
                labelKey: 'campaigns.campaigns',
                link: {
                    product: Products.cc,
                    route: '/campaigns',
                },
                accessData: {
                  permissions: {
                    permissionPath: 'CC.CAMPAIGN',
                  },
                  featureFlagOff: FeatureFlagKeys.sms_pro_2022_q_3
                },
                // items: [
                //     {
                //         id: uuid(),
                //         labelKey: 'campaigns.overview',
                //     },
                //     {
                //         id: uuid(),
                //         labelKey: 'campaigns.allCampaigns',
                //     },
                //     {
                //         id: uuid(),
                //         labelKey: 'campaigns.createCampaign',
                //     },
                // ],
              },
                // {
                //     id: uuid(),
                //     iconName: 'headset',
                //     labelKey: 'contactCentre',

                // },
                {
                    id: uuid(),
                    iconName: 'uni-chatbot',
                    labelKey: 'chatbot',
                    link: {
                        product: Products.conversation,
                        route: '/integrations/bot-builder',
                    },
                },
                {
                    id: uuid(),
                    iconName: 'uni-integrations',
                    labelKey: 'integrations.integrations',
                    link: {
                        product: Products.integrations,
                        route: '/',
                    },
                    items: [
                      {
                        id: uuid(),
                        labelKey: 'integrations.connectors',
                        link: {
                          product: Products.integrations,
                          route: '/connectors',
                        }
                      },
                      {
                        id: uuid(),
                        labelKey: 'integrations.myConnections',
                        link: {
                          product: Products.integrations,
                          route: '/connections-list',
                        }
                      },
                    ],
                    accessData: {
                      featureFlag: FeatureFlagKeys.integrations_2023_q_1
                    }
                },
                {
                  id: uuid(),
                  iconName: 'uni-authenticate',
                  labelKey: 'authenticate.authenticate',
                  link: {
                    product: Products.authenticate,
                    route: '/'
                  },
                  items: [
                    {
                      id: uuid(),
                      labelKey: 'authenticate.myApps',
                      link: {
                        product: Products.authenticate,
                        route: '/apps',
                      }
                    },
                    {
                      id: uuid(),
                      labelKey: 'authenticate.usageReports',
                      link: {
                        product: Products.authenticate,
                        route: '/reports',
                      }
                    }
                  ].map(item => ({
                    ...item,
                    accessData: {
                      permissions: {
                        permissionPath: 'AU',
                      },
                    },
                  })),
                },
                {
                  id: uuid(),
                  iconName: 'uni-ulink',
                  labelKey: 'ulink.ulink',
                  link: {
                    product: Products.ulink,
                    route: '/'
                  },
                  items: [
                    {
                      id: uuid(),
                      labelKey: 'ulink.singleUrl',
                      link: {
                        product: Products.ulink,
                        route: '/links/history',
                      }
                    },
                    {
                      id: uuid(),
                      labelKey: 'ulink.bulkRequests',
                      link: {
                        product: Products.ulink,
                        route: '/bulk',
                      }
                    }
                  ].map(item => ({
                    ...item,
                    accessData: {
                      permissions: {
                        permissionPath: 'TU',
                      },
                    },
                  })),
                },
                // {
                //     id: uuid(),
                //     iconName: 'plug',
                //     labelKey: 'integrations',
                //     isHidden: true,
                // },
            ],
            // groupExpandItem: {
            //     iconName: 'th',
            //     expandedLabelKey: 'lessProducts',
            //     collapsedLabelKey: 'allProducts',
            // },
        },
        {
            items: [
                {
                    id: uuid(),
                    iconName: 'uni-reports-logs',
                    labelKey: 'insights.insights',
                    items: [
                        {
                            id: uuid(),
                            labelKey: 'insights.dashboard',
                            link: {
                                product: Products.ci,
                                route: '/insights',
                            },
                            accessData: {
                                featureFlag: FeatureFlagKeys.ci_new_menu_2022_q_4
                            }
                        },
                        {
                            id: uuid(),
                            labelKey: 'insights.logAnalyser',
                            link: {
                                product: Products.ci,
                                route: '/insights/log-analyser',
                            },
                            accessData: {
                                featureFlag: FeatureFlagKeys.ci_new_menu_2022_q_4
                            }
                        },{
                            id: uuid(),
                            labelKey: 'insights.reports',
                            link: {
                                product: Products.ci,
                                route: '/insights/reports',
                            },
                            accessData: {
                                featureFlag: FeatureFlagKeys.ci_new_menu_2022_q_4
                            }
                        },
                        {
                            id: uuid(),
                            labelKey: 'campaigns.roiDashboards',
                            link: {
                                product: Products.campaign,
                                route: '/management/roi/dashboards',
                            },
                            accessData: {
                                featureFlag: FeatureFlagKeys.mcc_campaign_roi_goals_management_2024_q3
                            }
                        },
                        {
                            id: uuid(),
                            labelKey: 'insights.smsTrafficReport',
                            link: {
                                product: Products.cc,
                                route: '/report/traffic',
                            },
                            accessData: {
                                // matching unifonic_cc/config/menu/main_menu.yaml:100
                                permissions: {
                                    permissionPath: 'CC.TRAFFIC_REPORT',
                                    permissionsList: [
                                        Permission.seeAll,
                                        Permission.seeOwn,
                                    ],
                                },
                                featureFlag: FeatureFlagKeys.ci_old_menu_2022_q_4
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'insights.messageLogs',
                            link: {
                                product: Products.cc,
                                route: '/message/logs',
                            },
                            accessData: {
                                permissions: {
                                    permissionPath: 'CC.MESSAGE_LOG',
                                    permissionsList: [
                                        Permission.seeAll,
                                        Permission.seeOwn,
                                    ],
                                },
                                featureFlag: FeatureFlagKeys.ci_old_menu_2022_q_4
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'insights.voiceCallLogs',
                            link: {
                                product: Products.cc,
                                route: '/voice/notification-call-log',
                            },
                            accessData: {
                                permissions: {
                                    permissionPath: 'CC.VOICE_CALL_LOGS',
                                    permissionsList: [
                                        Permission.seeAll,
                                        Permission.seeOwn,
                                    ],
                                },
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'insights.maskedCallLogs',
                            link: {
                                product: Products.cc,
                                route: '/voice/masked-call-log',
                            },
                            accessData: {
                                permissions: {
                                    permissionPath: 'CC.VOICE_CALL_LOGS',
                                    permissionsList: [
                                        Permission.seeAll,
                                        Permission.seeOwn,
                                    ],
                                },
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'insights.scheduledReports',
                            link: {
                                product: Products.cc,
                                route: '/report/scheduled',
                            },
                            accessData: {
                                featureFlag: FeatureFlagKeys.scheduled_reports_2021_q_3,
                            }
                        },
                        {
                            id: uuid(),
                            labelKey: 'insights.conversationReport',
                            link: {
                                product: Products.uc,
                                route: '/consumption-reports',
                            },
                            accessData: {
                                featureFlag: FeatureFlagKeys.conversations_report_2022_q_3,
                                // matching unifonic_uc/config/menu/main_menu.yaml:123
                                permissions: {
                                    permissionPath: 'UC.CONSUMPTION_REPORTS',
                                },
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'insights.pushConsumptionReport',
                            link: {
                                product: Products.push,
                                route: '/push/consumption-reports',
                            },
                            accessData: {
                                permissions: {
                                    permissionPath: 'PU.APP',
                                    permissionsList: [
                                        Permission.seeOwn,
                                        Permission.seeUserOwn,
                                        Permission.superSee,
                                    ],
                                },
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'insights.pushLogs',
                            link: {
                                product: Products.push,
                                route: '/push/logs',
                            },
                            accessData: {
                                permissions: {
                                    permissionPath: 'PU.APP',
                                    permissionsList: [
                                        Permission.seeOwn,
                                        Permission.seeUserOwn,
                                        Permission.superSee,
                                    ],
                                },
                            },
                        },
                    ],
                },
                {
                    id: uuid(),
                    iconName: 'uni-admin',
                    labelKey: 'admin.admin',
                    items: [
                        {
                            id: uuid(),
                            labelKey: 'admin.users',
                            link: {
                                product: Products.uc,
                                route: '/users',
                            },
                            accessData: {
                                permissions: {
                                    permissionPath: 'UC.USER',
                                    permissionsList: [
                                        Permission.seeAll,
                                        Permission.seeOwn,
                                    ],
                                },
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'admin.subAccounts',
                            link: {
                                product: Products.uc,
                                route: '/sub-accounts',
                            },
                            accessData: {
                                permissions: {
                                    permissionPath: 'UC.SUB_ACCOUNT',
                                    permissionsList: [
                                        Permission.seeOwn,
                                    ],
                                },
                            },
                        },{
                          id: uuid(),
                          labelKey: 'admin.unishield',
                          link: {
                              product: Products.ci,
                              route: '/security/unishield',
                          },
                          accessData: {
                              featureFlag: FeatureFlagKeys.unishield_2024_q_3
                          }
                        },
                        {
                            id: uuid(),
                            labelKey: 'admin.security',
                            link: {
                                product: Products.uc,
                                route: '/security',
                            },
                            accessData: {
                                featureFlag: FeatureFlagKeys.security_ip_whitelisting_2023_q_3,
                                permissions: {
                                    permissionPath: 'UC.IP_WHITELIST',
                                    permissionsList: [
                                        Permission.updateOwn,
                                    ],
                                },
                            }
                        },
                        {
                            id: uuid(),
                            labelKey: 'admin.pendingRequests',
                            link: {
                                product: Products.uc,
                                route: '/requests',
                            },
                            accessData: {
                              additionalGrants: [AdditionalGrants.viewPendingRequests],
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'admin.requestsHistory',
                            link: {
                                product: Products.uc,
                                route: '/requests/history',
                            },
                            accessData: {
                              additionalGrants: [AdditionalGrants.viewPendingRequests],
                              permissions: {
                                permissionPath: 'UC.SUB_ACCOUNT',
                                permissionsList: [
                                    Permission.seeOwn,
                                ],
                            },
                            },
                        },
                    ],
                },
                {
                    id: uuid(),
                    iconName: 'uni-audiences',
                    labelKey: 'audiences.audiences',
                    items: [
                        {
                            id: uuid(),
                            labelKey: 'audiences.dashboard',
                            link: {
                                product: Products.audience,
                                route: '/dashboard',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'audiences.contacts',
                            link: {
                                product: Products.audience,
                                route: '/contacts',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'audiences.segments',
                            link: {
                                product: Products.audience,
                                route: '/segments',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'audiences.data',
                            link: {
                                product: Products.audience,
                                route: '/data',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'audiences.logs',
                            link: {
                                product: Products.audience,
                                route: '/logs',
                            },
                        },
                    ],
                    accessData: {
                      featureFlag: FeatureFlagKeys.audiences_2023_q_3
                    }
                },
                {
                    id: uuid(),
                    iconName: 'address-book',
                    labelKey: 'directory.directory',
                    items: [
                        // {
                        //     id: uuid(),
                        //     labelKey: 'directory.customerDataPlatform',
                        // },
                        {
                            id: uuid(),
                            labelKey: 'directory.contacts',
                            link: {
                                product: Products.uc,
                                route: '/contacts',
                            },
                            accessData: {
                              additionalGrants: [AdditionalGrants.audienceAccess],
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'directory.groups',
                            link: {
                                product: Products.uc,
                                route: '/groups',
                            },
                            accessData: {
                              additionalGrants: [AdditionalGrants.audienceAccess],
                            },
                        },
                    ],
                },
                {
                    id: uuid(),
                    iconName: 'uni-channels',
                    labelKey: 'channels.channels',
                    items: [
                        {
                            id: uuid(),
                            labelKey: 'channels.smsChannels',
                            link: {
                                product: Products.cc,
                                route: '/sendername',
                            },
                            accessData: {
                                permissions: {
                                    permissionPath: 'CC.SENDER_NAME',
                                    permissionsList: [
                                        Permission.seeAll,
                                        Permission.seeOwn,
                                    ],
                                },
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.voice',
                            link: {
                                product: Products.cc,
                                route: '/callers',
                            },
                            accessData: {
                                permissions: {
                                    permissionPath: 'CC.CALLER',
                                    permissionsList: [
                                        Permission.seeAll,
                                        Permission.seeOwn,
                                    ],
                                },
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.whatsapp',
                            link: {
                                product: Products.cc,
                                route: '/channels/whatsapp',
                            },
                            accessData: {
                                featureFlag: FeatureFlagKeys.channels_whatsapp_2022_q_1,
                            }
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.facebook',
                            link: {
                                product: Products.cc,
                                route: '/channels/facebook',
                            },
                            accessData: {
                                featureFlag: FeatureFlagKeys.channels_whatsapp_2022_q_1,
                            }
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.twitter',
                            link: {
                                product: Products.cc,
                                route: '/channels/twitter',
                            },
                            accessData: {
                                featureFlag: FeatureFlagKeys.channel_twitter_2020_q_1,
                            }
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.webWidget',
                            link: {
                                product: Products.cc,
                                route: '/channels/web-widget',
                            },
                            accessData: {
                              featureFlag: FeatureFlagKeys.channels_webwidget_2022_q_4,
                            }
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.appleBusinessChat',
                            link: {
                                product: Products.cc,
                                route: '/channels/apple-business-messages',
                            },
                            accessData: {
                              featureFlag: FeatureFlagKeys.channel_abc_2021_q_1,
                            }
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.instagram',
                            link: {
                                product: Products.cc,
                                route: '/channels/instagram',
                            },
                            accessData: {
                              featureFlag: FeatureFlagKeys.channels_instagram_2022_q_4,
                            }
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.pushNotifications',
                            link: {
                                product: Products.push,
                                route: '/channel/push',
                            },
                            accessData: {
                              permissions: {
                                  permissionPath: 'PU.APP',
                                  permissionsList: [
                                      Permission.seeOwn,
                                  ],
                              },
                            }
                        },
                        // {
                        //     id: uuid(),
                        //     labelKey: 'channels.appleBusinessChat',
                        // },
                        // {
                        //     id: uuid(),
                        //     labelKey: 'channels.email',
                        // },
                    ],
                },
                {
                    id: uuid(),
                    iconName: 'uni-library',
                    labelKey: 'library.library',
                    items: [
                        {
                            id: uuid(),
                            labelKey: 'library.audio',
                            link: {
                                product: Products.cc,
                                route: '/audio-library'
                            },
                            accessData: {
                                permissions: {
                                    permissionPath: 'CC.CAMPAIGN',
                                    permissionsList: [
                                        Permission.seeAll,
                                        Permission.seeOwn,
                                        Permission.seeUserOwn,
                                        Permission.superSee
                                    ]
                                }
                            }
                        }
                    ],
                }
            ],
        },
        {
            items: [
                {
                    id: uuid(),
                    iconName: 'uni-developers',
                    labelKey: 'developers.developers',
                    items: [
                        {
                          id: uuid(),
                          labelKey: 'developers.applications',
                          link: {
                            product: Products.uc,
                            route: '/applications'
                          },
                          accessData: {
                            // matching unifonic_cc/config/menu/main_menu.yaml:57
                            permissions: {
                              permissionPath: 'CC.APPS_ID',
                              permissionsList: [
                                Permission.seeOwn,
                                Permission.smppSeeAll
                              ],
                            },
                          },
                        },
                        {
                            id: uuid(),
                            labelKey: 'developers.documentation',
                            link: {
                                externalUrl: 'https://docs.unifonic.com',
                            },
                            accessData: {
                                permissions: {
                                    permissionPath: 'CC.DOCUMENTATION_LINK',
                                    permissionsList: [
                                        Permission.see,
                                    ],
                                },
                            },
                        },
                      {
                        id: uuid(),
                        labelKey: 'webhook.webhook',
                        link: {
                          product: Products.webhook,
                          route: '/webhook',
                        },
                        accessData: {
                          // permissions: {
                          //   permissionPath: 'WH',
                          // },
                        },
                      },
                      {
                        id: uuid(),
                        labelKey: 'developers.keys',
                        link: {
                          product: Products.uc,
                          route: '/api-keys',
                        },
                        accessData: {
                          featureFlag: FeatureFlagKeys.api_keys_2022_q_4,
                          permissions: {
                            permissionPath: 'UC.API_KEYS',
                            permissionsList: [
                                Permission.seeAll,
                                Permission.seeOwn
                            ]
                          }
                        },
                      },
                        //                 {
                        //                     id: uuid(),
                        //                     labelKey: 'developers.webhooks',
                        //                 },
                    ],
                },
                //         {
                //             id: uuid(),
                //             iconName: 'question-circle',
                //             labelKey: 'help',
                //         },
                //     ],
                // },
            ],
        },
    ],
};
