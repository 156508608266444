import { Component, OnInit } from '@angular/core';
import { UniPaginationFacade } from '@unifonic/common';
import { filter, map } from 'rxjs/operators';
import { SortData } from 'src/app/shared/data-sort/data-sort.models';
import { MessageLogsFiltersStore } from '../../store/message-logs-filters.store';
import { MessageLogsTableSmsWhatsappStore } from './message-logs-table-sms-whatsapp.store';

@Component({
  selector: 'app-message-logs-table-sms-whatsapp',
  templateUrl: './message-logs-table-sms-whatsapp.component.html',
  styleUrls: ['./message-logs-table-sms-whatsapp.component.scss'],
  providers: [
    MessageLogsTableSmsWhatsappStore,
    {
      provide: UniPaginationFacade,
      useValue: {
        setPage: () => {}
      }
    }
  ]
})
export class MessageLogsTableSmsWhatsappComponent implements OnInit {

  vm$ = this.store.vm$

  sortData$ = this.messageLogsFiltersStore.sortData$
  sortDataStatus$ = this.messageLogsFiltersStore.sortData$.pipe(filter(({sortBy}) => sortBy === 'status'))
  sortDataDate$ = this.messageLogsFiltersStore.sortData$.pipe(filter(({sortBy}) => sortBy === 'sentByClient'))
  sortDataStatusActive$ = this.messageLogsFiltersStore.sortData$.pipe(map(({sortBy}) => sortBy === 'status'))
  sortDataDateActive$ = this.messageLogsFiltersStore.sortData$.pipe(map(({sortBy}) => sortBy === 'sentByClient'))

  page$ = this.messageLogsFiltersStore.page$.pipe(map(p => p || 1))
  limit$ = this.messageLogsFiltersStore.limit$

  columns = {
    id: 'id',
    sender: 'sender',
    product: 'product',
    recipient: 'recipient',
    date: 'date',
    sentAt: 'sentAt',
    status: 'status',
    action: 'action',
    details: 'details',
    empty: 'empty'
  }

  constructor(
    private store: MessageLogsTableSmsWhatsappStore,
    private messageLogsFiltersStore: MessageLogsFiltersStore,
  ) { }

  ngOnInit(): void {
  }

  toggleDetails(id: string){
      this.store.setSelectedItemId(id)
  }

  trackId(index, item){
    return `${item.id}`
  }

  sort([sortBy, sortDir]: SortData){
    this.messageLogsFiltersStore.patchState(
      {
      sortBy: sortDir.length ? sortBy : '',
      sortDir: sortDir,
      autoload: true
    })

    this.store.resetSelectedItemId()
  }

  onPageChange(page: any){
      this.messageLogsFiltersStore.patchState({page, autoload: true})
      this.store.resetSelectedItemId()
  }
}
