<ng-container *ngIf="vm$ | async as vm">
  {{'ci.common.unishield' | translate}}
  <div class="filter-controls">
    <app-filter-panel-account-id></app-filter-panel-account-id>
    <app-filter-panel-date></app-filter-panel-date>
  </div>
  <app-overview></app-overview>
  <app-geopermissions></app-geopermissions>
  <app-blocked-words></app-blocked-words>
</ng-container>
