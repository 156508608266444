<uni-label>
    {{'ci.logAnalyzer.details.labels.direction' | translate}}
</uni-label>

<app-form-multi-select
    [options]="directionEntities$ | async"
    [value]="directionCurrentValue$ | async"
    (valueChange)="directionValueChange($event)"
    [defer]="0"
    [placeholder]="'ci.logAnalyzer.details.labels.direction' | translate"
></app-form-multi-select>
<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
    {{'ci.common.fieldRequired' | translate}}
</uni-form-field-error>
