export const environment = {
    "production": "%IS_PRODUCTION_CI_PLACEHOLDER%",
    "environmentName": "%ENVIRONMENT_NAME_CI_PLACEHOLDER%",
    "launchDarklyKey": "%LAUNCHDARKLY_KEY_CI_PLACEHOLDER%",
    "ucUrl": "%UC_URL_CI_PLACEHOLDER%",
    "ccUrl": "%CC_URL_CI_PLACEHOLDER%",
    "convUrl": "%CONV_URL_CI_PLACEHOLDER%",
    "convApiUrl": "%CONV_API_URL_CI_PLACEHOLDER%",
    "ciGraphQlUrl": "%CI_GRAPHQL_URL_CI_PLACEHOLDER%",
    "ciRestUrl": "%CI_REST_URL_CI_PLACEHOLDER%",
    "ciRestReportingUrl": "%CI_REST_REPORTING_URL_CI_PLACEHOLDER%",
    "apiUrl": "%CC_URL_CI_PLACEHOLDER%/app/api/v2",
    "mixpanelToken": "%MIXPANEL_TOKEN_CI_PLACEHOLDER%",
    "userpilotToken": "%USERPILOT_TOKEN_CI_PLACEHOLDER%",
    "userpilotKey": "%USERPILOT_KEY_CI_PLACEHOLDER%",
    "campaignUrl": "%CAMPAIGN_URL_CI_PLACEHOLDER%",

    "zainBrowserUrlOrigin": "%ZAIN_BROWSER_URL_ORIGIN_PLACEHOLDER%",
    "zainRequestHost": "%ZAIN_REQUEST_HOST_PLACEHOLDER%",
    "mainRequestHost": "%MAIN_REQUEST_HOST_PLACEHOLDER%"
    
};