import { Component, OnInit } from '@angular/core';
import { status, UniPaginationFacade } from '@unifonic/common';
import { filter, map } from 'rxjs/operators';
import { SortData } from 'src/app/shared/data-sort/data-sort.models';
import { MessageLogsFiltersStore } from '../../store/message-logs-filters.store';
import { MessageLogsTableVoiceStore } from './message-logs-table-voice.store';

@Component({
  selector: 'app-message-logs-table-voice',
  templateUrl: './message-logs-table-voice.component.html',
  styleUrls: ['./message-logs-table-voice.component.scss'],
  providers: [
    MessageLogsTableVoiceStore,
    {
      provide: UniPaginationFacade,
      useValue: {
        setPage: () => {}
      }
    }
  ]
})
export class MessageLogsTableVoiceComponent implements OnInit {

  vm$ = this.store.vm$

  sortData$ = this.messageLogsFiltersStore.sortData$
  sortDataDirection$ = this.messageLogsFiltersStore.sortData$.pipe(filter(({sortBy}) => sortBy === 'direction'))
  sortDataDate$ = this.messageLogsFiltersStore.sortData$.pipe(filter(({sortBy}) => sortBy === 'requestReceivedAt'))
  sortDataDirectionActive$ = this.messageLogsFiltersStore.sortData$.pipe(map(({sortBy}) => sortBy === 'direction'))
  sortDataDateActive$ = this.messageLogsFiltersStore.sortData$.pipe(map(({sortBy}) => sortBy === 'requestReceivedAt'))

  page$ = this.messageLogsFiltersStore.page$.pipe(map(p => p || 1))
  limit$ = this.messageLogsFiltersStore.limit$

  columns = {
    start: 'start',
    carrier: 'carrier',
    direction: 'direction',
    sourceType: 'sourceType',
    sender: 'sender',
    status: 'status',
    duration: 'duration',
    recipient: 'recipient',
    action: 'action',
    details: 'details',
    empty: 'empty'
  }

  displayedColumns = [
    'sender',
    'recipient',
    'direction',
    'start',
    'status',
    'action'
  ]

  constructor(
    private store: MessageLogsTableVoiceStore,
    private messageLogsFiltersStore: MessageLogsFiltersStore,
  ) { }

  ngOnInit(): void {
  }

  toggleDetails(id: string){
      this.store.setSelectedItemId(id)
  }

  trackId(index, item){
    return `${item.id}`
  }

  sort([sortBy, sortDir]: SortData){
    this.messageLogsFiltersStore.patchState(
      {
      sortBy: sortDir.length ? sortBy : '',
      sortDir: sortDir,
      autoload: true
    })

    this.store.resetSelectedItemId()
  }

  onPageChange(page: any){
      this.messageLogsFiltersStore.patchState({page, autoload: true})
      this.store.resetSelectedItemId()
  }
}
