<ng-container *ngIf="(vm$ | async) as vm">

    <div class="data-panel">
        <div class="data-panel-items">
            <strong>{{'ci.logAnalyzer.table.numberOfItems' | translate: {items: vm.totalCount} }}</strong>
        </div>
        <div class="data-panel-actions">
            <app-message-logs-refresh-control></app-message-logs-refresh-control>
            <app-message-logs-report-generator></app-message-logs-report-generator>
        </div>
    </div>

    <uni-table>
        <app-loading-mask *ngIf="vm.loading"></app-loading-mask>
        <table
            cdk-table
            [dataSource]="vm.entities"
            [trackBy]="trackId"
            multiTemplateDataRows
        >
            <tr
                cdk-header-row
                *cdkHeaderRowDef="vm.displayedColumns"
            ></tr>

            <tr
                cdk-header-row
                *cdkHeaderRowDef="['empty']"
                [hidden]="!!vm.totalCount"
            ></tr>

            <ng-container [cdkColumnDef]="columns.empty">
                <th
                    [attr.colspan]="vm.displayedColumns.length"
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                >
                    <div class="empty-msg">
                        {{ "ci.common.weFoundNothing" | translate}}
                    </div>
                </th>
            </ng-container>
            <tr
                cdk-row
                *cdkRowDef="let row; columns: vm.displayedColumns; let isOdd = odd; let isEven = even; let isLast = last"
                [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"
            ></tr>
            <tr
                cdk-row
                *cdkRowDef="let row; columns: ['details']; let isOdd = odd; let isEven = even; let isLast = last"
                [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"
                [hidden]="!row.active"
            ></tr>


            <ng-container [cdkColumnDef]="columns.details">
                <td
                    [attr.colspan]="vm.displayedColumns.length"
                    cdk-cell
                    *cdkCellDef="let element"
                >
                    <app-message-logs-table-details
                        *ngIf="element.active"
                        [messageId]="element.messageId"
                        [elMessageId]="element.elMessageId"
                        [isRealTime]="element.isRealTime"
                        [submitDate]="element.date"
                    ></app-message-logs-table-details>
                </td>

            </ng-container>

            <ng-container [cdkColumnDef]="columns.sender">
                <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                >
                    {{'ci.logAnalyzer.table.headers.sender' | translate}}
                </th>
                <td
                    cdk-cell
                    *cdkCellDef="let element"
                >
                    {{ element.sender }}
                </td>
            </ng-container>
            <ng-container [cdkColumnDef]="columns.product">
                <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                >
                    {{'ci.logAnalyzer.table.headers.product' | translate}}
                </th>
                <td
                    cdk-cell
                    *cdkCellDef="let element"
                >
                    {{ element.productLabel }}
                </td>
            </ng-container>
            <ng-container [cdkColumnDef]="columns.recipient">
                <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                >
                    {{'ci.logAnalyzer.table.headers.recipient' | translate}}
                </th>
                <td
                    cdk-cell
                    *cdkCellDef="let element"
                >
                    <div class="text-overflow" [title]="element.recipient">
                        {{ element.recipient }}
                    </div>
                </td>
            </ng-container>
            <ng-container [cdkColumnDef]="columns.date">
                <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                >
                    <div class="header-cell sort-header left">
                        {{'ci.common.sentToOperatorAt' | translate}}
                        <app-data-sort
                            [fieldName]="'sentByClient'"
                            [value]="sortDataDate$ | async"
                            [isActive]="sortDataDateActive$ | async"
                            (valueChange)="sort( $event )"
                        ></app-data-sort>
                    </div>
                </th>
                <td
                    cdk-cell
                    *cdkCellDef="let element"
                >
                  <ng-container  *ngIf="!element.messageRejected">
                    {{ element.date | dateFormat: 'DD-MM-YYYY HH:mm:ss'}}
                  </ng-container>
                </td>
            </ng-container>
            <ng-container [cdkColumnDef]="columns.sentAt">
              <th
                  cdk-header-cell
                  *cdkHeaderCellDef
                  #thead
              >
                  <div class="header-cell sort-header left">
                      {{'ci.common.sentByClient' | translate}}
                      <app-data-sort
                          [fieldName]="'sentByClient'"
                          [value]="sortDataDate$ | async"
                          [isActive]="sortDataDateActive$ | async"
                          (valueChange)="sort( $event )"
                      ></app-data-sort>
                  </div>
              </th>
              <td
                  cdk-cell
                  *cdkCellDef="let element"
              >
                  {{ element.date | dateFormat: 'DD-MM-YYYY HH:mm:ss'}}
              </td>
          </ng-container>
            <ng-container [cdkColumnDef]="columns.status">
                <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                >
                    <div class="header-cell sort-header left">
                        {{'ci.logAnalyzer.table.headers.status' | translate}}
                        <app-data-sort
                            *ngIf="vm.statusSortingVisibility"
                            [fieldName]="'status'"
                            [value]="sortDataStatus$ | async"
                            [isActive]="sortDataStatusActive$ | async"
                            (valueChange)="sort( $event )"
                        ></app-data-sort>
                    </div>

                </th>
                <td
                    cdk-cell
                    *cdkCellDef="let element"
                >
                    <div class="status">
                        <!-- <span
                            class="status-dot"
                            [attr.status]="element.status"
                        ></span> -->
                        <span>
                            {{ element.status }}
                        </span>
                        <i
                            *ngIf="element.deliveryNotConfirmedTooltip"
                            [matTooltip]="'ci.common.tips.deliveryNotConfirmed' | translate"
                            matTooltipPosition="left"
                            class="far fa-info-circle"></i>
                    </div>
                </td>
            </ng-container>
            <ng-container [cdkColumnDef]="columns.action">
                <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                    [style.width.px]="100"
                    [style.textAlign]="'right'"
                >

                    {{'ci.logAnalyzer.table.headers.action' | translate}}
                </th>
                <td
                    cdk-cell
                    *cdkCellDef="let element"
                    [style.textAlign]="'right'"
                    class="cell-action"
                >
                    <uni-button
                        *ngIf="vm.typeWhatsapp || !element.messageRejected"
                        type="button"
                        [style]="'secondary'"
                        [size]="'small'"
                        [isMargin]="false"
                        [autoWidth]="true"
                        [noHorizontalSpace]="true"
                        [noVerticalSpace]="true"
                        (click)="toggleDetails(element.recordId)"
                    >

                        <i class="fa fa-chevron-down" *ngIf="!element.active"></i>
                        <i class="fa fa-chevron-up" *ngIf="element.active"></i>

                    </uni-button>

                </td>
            </ng-container>
        </table>
    </uni-table>
    <div class="table-pagination">
        <app-pagination
            [items]="vm.totalCount"
            [itemsPerPage]="limit$ | async"
            (pageChange)="onPageChange($event)"
            [valueTracker]="page$ | async"
        ></app-pagination>
    </div>
</ng-container>
