import { ApiMessageLogsListParams, SortingDirection } from "../log-analyser.models"
import { Channels } from "src/app/shared/store-mocks/channels.store"

export const mapSortingOptionsToFilterParams = (sortBy: string, sortDir: SortingDirection, channel = Channels.SMS): {orderBy?: ApiMessageLogsListParams['orderBy']} => {
    if(!sortBy || !sortDir){
        return {}
    }

    if (channel === Channels.VOICE) {
        if (sortBy === 'sentByClient') {
            sortBy = 'requestReceivedAt'
        }
    }

    return {
        orderBy: {
            field: sortBy,
            direction: sortDir
        }
    }
}
